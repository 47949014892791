var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',[_vm._m(0),(_vm.currentContentVisibility=='middle')?_c('div',{ref:"aboutDetail",staticClass:"about-detail"},[_c('abouts-wrapper2',{attrs:{"about-data":_vm.aboutData},on:{"close-about-detail":_vm.closeAboutDetail}})],1):_vm._e(),_c('div',{staticClass:"bg",style:({
    backgroundImage: _vm.innerWidth > 768 || _vm.aboutData.video && _vm.aboutData.video.length != 0
      ?`url(${_vm.aboutData.image})`:`url(${_vm.aboutData.imagemobile})`,
    backgroundColor: _vm.innerWidth > 768 ? '':_vm.aboutData.background ,
    backgroundSize: _vm.innerWidth > 768 ||_vm.aboutData.video&&_vm.aboutData.video.length!=0 ? 'cover' : 'contain',
    backgroundPosition: _vm.innerWidth > 768 ? 'top center' : 'center',
  })},[(_vm.currentContentVisibility=='full')?_c('button',{staticClass:"btn-close-content fixed",style:({backgroundColor:`#fff`, borderColor:_vm.aboutData.color,color:_vm.aboutData.color}),on:{"click":_vm.closeAboutContentFullDetail}},[_c('span',{staticClass:"close",style:({backgroundColor:_vm.aboutData.color, color:`#fff`})},[_vm._v("×")]),_c('span',{staticClass:"btn-back"},[_vm._v(_vm._s(_vm.$t("app.back")))])]):_vm._e(),(_vm.aboutData.video&&_vm.aboutData.video.length!=0)?_c('div',{staticClass:"video-overlay",on:{"mouseover":_vm.showButton,"mouseleave":_vm.hideButton}},[_c('video',{ref:"video",attrs:{"src":_vm.aboutData.video&&_vm.aboutData.video[0],"controls":_vm.innerWidth <= 768,"volume":_vm.volume,"autoplay":"","loop":"","muted":""},domProps:{"muted":true}}),(_vm.showPlayPauseButton)?_c('button',{staticClass:"play-pause-button",on:{"click":_vm.togglePlayPause}},[(_vm.isPlaying)?_c('i',{staticClass:"fas fa-pause"}):_c('i',{staticClass:"fas fa-play"})]):_vm._e(),(_vm.showPlayPauseButton)?_c('div',{staticClass:"volume-controls"},[_c('button',{on:{"click":_vm.toggleMute}},[(_vm.isMuted)?_c('i',{staticClass:"fas fa-volume-mute"}):_c('i',{staticClass:"fas fa-volume-up"})]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.volume),expression:"volume"}],attrs:{"type":"range","min":"0","max":"1","step":"0.1"},domProps:{"value":(_vm.volume)},on:{"input":_vm.setVolume,"__r":function($event){_vm.volume=$event.target.value}}})]):_vm._e()]):_vm._e()]),(_vm.currentContentVisibility=='middle')?_c('div',{staticClass:"list-about"},[_c('abouts-wrapper1',{attrs:{"about-data":_vm.aboutData,"about-menu":_vm.aboutMenu},on:{"close-about-list":_vm.closeAboutList,"close-about-contentfull-list":_vm.closeAboutContentFullList}})],1):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('head',[_c('link',{attrs:{"rel":"stylesheet","href":"https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.6.0/css/all.min.css","integrity":"sha512-Kc323vGBEqzTmouAECnVceyQqyqdsSiqLQISBL29aUW4U/M7pSPA/gEUZQqv1cwx4OnYxTxve5UMg5GT6L4JJg==","crossorigin":"anonymous","referrerpolicy":"no-referrer"}})])
}]

export { render, staticRenderFns }